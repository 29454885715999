/*global FitMix*/

import { Controller } from '@hotwired/stimulus'

import { hide, show } from '../../lib/helpers'

export default class VirtualTryOnController extends Controller {
  static targets = ['container', 'fitmixRoot', 'startControl', 'gallery', 'hiddenForNarrowViewports']
  static values = { apiKey: String, product: String, lang: String }

  connect() {
    this.attachFittingBoxScript()
  }

  attachFittingBoxScript() {
    if (!document.getElementById('fittingbox-snippet')) {
      const script = document.createElement('script')
      script.id = 'fittingbox-snippet'
      script.src = 'https://vto-advanced-integration-api.fittingbox.com/index.js'
      document.head.appendChild(script)
      script.addEventListener('load', () => this.activateFittingBox())
    } else {
      this.activateFittingBox()
    }
  }

  activateFittingBox() {
    const params = {
      apiKey: this.apiKeyValue,
      lang: this.langValue,
      onStopVto: this.onStopVto.bind(this),
    }

    this.fitmixInstance = FitMix.createWidget(this.fitmixRootTarget.id, params, () =>
      this.fitmixInstance.setFrame(this.productValue)
    )
  }

  start() {
    hide(this.startControlTarget)
    this.fitmixInstance.startVto('live')
    hide(this.galleryTarget)
    show(this.containerTarget)

    this.hiddenForNarrowViewportsTarget.dataset.vtoActive = 'true'
  }

  onStopVto() {
    hide(this.containerTarget)
    show(this.galleryTarget)
    show(this.startControlTarget)

    this.hiddenForNarrowViewportsTarget.dataset.vtoActive = 'false'
  }

  disconnect() {
    this.close()
    this.onStopVto()
  }

  close() {
    if (this.fitmixInstance) {
      this.fitmixInstance.stopVto()
    }
  }
}

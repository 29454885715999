import { Controller } from '@hotwired/stimulus'
import { hide, show } from '../../lib/helpers'
import { debounce } from 'lodash'

export default class HeaderSearchController extends Controller {
  static targets = ['form', 'input', 'results', 'spinner', 'searchResults']

  performSearchDebounced = debounce(this.performSearch.bind(this), 500)

  submit(e) {
    if (!this.formTarget.classList.contains('is-active')) {
      e.preventDefault()
      this.formTarget.classList.add('is-active')
      this.inputTarget.focus()
      return
    }

    this.updateSearchResults()
    if (this.inputTarget.value.length > 0) {
      show(this.spinnerTarget)
      this.performSearchDebounced()

      this.resultsTarget.classList.add('is-active')
    } else {
      this.resultsTarget.classList.remove('is-active')
    }
  }

  cancel() {
    this.formTarget.classList.remove('is-active')
    this.resultsTarget.classList.remove('is-active')
    this.updateSearchResults()
  }

  performSearch() {
    const fetchUrl = this.application.fetch || fetch
    const url = new URL(this.formTarget.action)

    url.searchParams.append('product_search_query', this.inputTarget.value)
    url.searchParams.append('lite_search', true)
    url.searchParams.append('category', '')

    fetchUrl(url)
      .then((response) => response.text())
      .then((html) => {
        this.updateSearchResults(html)
      })
  }

  updateSearchResults(html = '') {
    hide(this.spinnerTarget)
    this.searchResultsTarget.innerHTML = html
  }
}

import { Controller } from '@hotwired/stimulus'
import { show } from '../../lib/helpers'
import flatpickr from 'flatpickr'

export default class extends Controller {
  static targets = [
    'locationSelect',
    'datePicker',
    'dateContainer',
    'hourSelect',
    'hourSelectContainer',
    'minuteSelectContainer',
    'locationData',
  ]

  connect() {
    const locationDataElement = this.locationDataTarget
    const locationHoursJson = locationDataElement.getAttribute('data-hours')
    const today = this.currentDate()
    const nextYear = new Date()

    this.locationHours = JSON.parse(locationHoursJson)
    this.dayNames = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday']
    this.startDate = today.toISOString().split('T')[0]
    nextYear.setFullYear(today.getFullYear() + 1)
    this.endDate = nextYear.toISOString().split('T')[0]

    this.updateDatePicker()
    this.updateHourSelect()
  }

  updateDatePicker() {
    const locationId = this.locationSelectTarget.value
    if (locationId) {
      show(this.dateContainerTarget)

      const location = this.locationHours.find((loc) => String(loc.id) === locationId)
      const hours = location.hours

      flatpickr(this.datePickerTarget, {
        minDate: this.disableFromToday(),
        disable: this.getDisabledDays(this.dayNames, hours, this.startDate, this.endDate),
        allowInput: true,
      })
    }
  }

  updateHourSelect() {
    const selectedDate = this.datePickerTarget.value
    const dayName = this.getDayName(this.dayNames, new Date(selectedDate))
    const locationId = this.locationSelectTarget.value
    const location = this.locationHours.find((loc) => String(loc.id) === locationId)
    const hours = location?.hours

    if (hours) {
      show(this.hourSelectContainerTarget)
      show(this.minuteSelectContainerTarget)

      const availableHours = this.getAvailableHours(hours, dayName)
      this.populateHourOptions(this.hourSelectTarget, availableHours)
    }
  }

  getLocationHours() {
    const locationId = this.locationSelectTarget.value
    const location = this.locationHours.find((loc) => String(loc.id) === locationId)

    return location?.hours || {}
  }

  disableFromToday(days = 2) {
    let date = new Date(this.currentDate() + days * 24 * 60 * 60 * 1000)
    const offset = date.getTimezoneOffset()
    date = new Date(date.getTime() - offset * 60 * 1000)

    return date.toISOString().split('T')[0]
  }

  getAvailableHours(hours, dayName) {
    const day = hours[dayName]
    const openTime = day?.['open_time']
    const closeTime = day?.['close_time']

    return openTime && closeTime ? this.getHourOptions(openTime, closeTime) : []
  }

  getHourOptions(openTime, closeTime) {
    const openHour = parseInt(openTime.split(':')[0], 10)
    const closeHour = parseInt(closeTime.split(':')[0], 10)
    const hours = []
    for (let hour = openHour; hour <= closeHour; hour++) {
      hours.push(hour)
    }
    return hours
  }

  populateHourOptions(hourSelect, hours) {
    hourSelect.innerHTML = ''
    hours.forEach((hour) => {
      const option = document.createElement('option')
      option.value = hour
      option.textContent = hour
      hourSelect.appendChild(option)
    })
  }

  getDayName(dayNames, date) {
    return dayNames[date.getDay()]
  }

  getDisabledDays(dayNames, hours, startDate, endDate) {
    const disabledDates = []
    const start = new Date(startDate)
    const end = new Date(endDate)
    let currentDate = new Date(start)
    while (currentDate <= end) {
      const dayName = dayNames[currentDate.getDay()]
      if (hours[dayName]?.closed) {
        disabledDates.push(currentDate.toISOString().split('T')[0])
      }
      currentDate.setDate(currentDate.getDate() + 1)
    }
    return disabledDates
  }

  currentDate() {
    return new Date(this.datePickerTarget.dataset.currentDate)
  }
}
